// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-template-jsx": () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\src\\templates\\pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\src\\templates\\postTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-project-template-jsx": () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\src\\templates\\projectTemplate.jsx" /* webpackChunkName: "component---src-templates-project-template-jsx" */),
  "component---src-pages-404-jsx": () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\src\\pages\\blog\\index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-contact-jsx": () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\src\\pages\\contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\src\\pages\\projects\\index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */)
}

exports.data = () => import("C:\\Users\\Michael\\Documents\\Github\\wobsite\\.cache\\data.json")

