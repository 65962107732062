module.exports = [{
      plugin: require('C:/Users/Michael/Documents/Github/wobsite/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Michael/Documents/Github/wobsite/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Michael/Documents/Github/wobsite/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
